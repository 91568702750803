<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
        <v-btn
          :color="$store.state.primaryColor"
          outlined
          small
          @click="newAccount"
        >
          {{ $t("new account") }}
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex
        xs12
        sm4
        md4
        lg4
        v-if="!selected && ownersBusinesses.length"
        class="py-1 px-1"
      >
        <v-select
          dense
          solo
          class="caption"
          :label="$t('search business')"
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="selectedShop"
          @change="onSelectShop"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search accounts')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="white" @click="getAccounts()"
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh table") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="accountingDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="accountingDate"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              in
              solo
            ></v-text-field>
          </template>
          <v-date-picker v-model="accountingDate" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"
              >{{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(accountingDate);
                dateFilter();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm12 md12 lg12 class="text-right mb-4">
        <!--sort buttons-->

        <v-btn
          :color="
            button.selected
              ? $store.state.secondaryColor
              : $store.state.primaryColor
          "
          x-small
          outlined
          class="mx-1 mt-1"
          v-for="button in menu"
          :key="button.title"
          @click="sortBy(button.action)"
        >
          <span class="caption text-lowercase">{{ $t(button.title) }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="filteredAccounts"
          :sort-by.sync="sortByDate"
          :sort-desc.sync="sortDesc"
          :search="search"
          :items-per-page="20"
        >
          <template v-slot:[`item.businessId`]="{ item }">
            {{ getBusinessName(item.businessId) }}
          </template>

          <template v-slot:[`item.dateTime`]="{ item }">
            {{ item.dateTime }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            {{ currencyFormat(item.amount) }}
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              left
              :color="$store.state.primaryColor"
              @click="editAccount(item.id)"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              left
              :color="$store.state.secondaryColor"
              @click="viewAccount(item.id)"
              >visibility</v-icon
            ><v-icon
              small
              left
              color="red"
              @click="deleteDialog(item.id, item.accountName)"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("account deleted successfully") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not delete account. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="delDialog" persistent max-width="290">
      <v-card>
        <div class="text-center">
          <v-btn class="my-5" outlined large fab color="red darken-4">
            <v-icon>warning</v-icon>
          </v-btn>
        </div>

        <v-card-text class="text-center">
          <p>{{ $t("are you sure") }}?</p>
          <p>
            {{ $t("do you really want to delete the") }}
            <span class="font-weight-bold">{{ accountName }}</span>
            {{ $t("account") }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :color="$store.state.secondaryColor"
            text
            small
            @click="delDialog = false"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="$store.state.primaryColor"
            text
            :loading="loading"
            small
            @click="deleteAccount"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { format } from "date-fns";
import db from "@/plugins/fb";
export default {
  data: () => ({
    accountingDate: format(new Date(), "yyyy-MM-dd"),
    loading: false,
    delDialog: false,
    modal: false,
    sortDesc: true,
    sortByDate: "dateTime",
    search: "",
    snackSuccess: false,
    snackError: false,
    accountId: "",
    accountName: "",
    filteredAccounts: [],
    businesses: [],
    accounts: [],
    selectedShop: "",
  }),

  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    menu() {
      return [
        {
          title: this.$t("all"),
          action: "all",
          selected: true,
        },
        {
          title: this.$t("assets"),
          action: "assets",
          selected: false,
        },
        {
          title: this.$t("liabilities"),
          action: "liabilities",
          selected: false,
        },
        {
          title: this.$t("expenses"),
          action: "expenses",
          selected: false,
        },
        {
          title: this.$t("revenues"),
          action: "revenues",
          selected: false,
        },
        {
          title: this.$t("owners equity"),
          action: "ownersEquity",
          selected: false,
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("account name"),
          value: "accountName",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("account type"),
          value: "accountType",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("date"),
          value: "dateTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("amount"),
          value: "amount",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("actions"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  created() {
    this.getAccounts();
  },

  methods: {
    getAccounts() {
      this.loading = true;
      this.accounts = [];

      this.menu[0].selected = true;
      this.menu[1].selected = false;
      this.menu[2].selected = false;
      this.menu[3].selected = false;
      this.menu[4].selected = false;
      this.menu[5].selected = false;
      if (this.selected) {
        db.collection("accounts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.accounts.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.accounts.filter(
                (item) => item.dateTime == this.$store.state.todaysDate
              );
              this.filteredAccounts = filtered;
            } else {
              this.filteredAccounts = this.accounts;
            }

            //keep accounts in vuex store
            this.$store.commit("SET_ACCOUNTS", this.accounts);
            this.loading = false;
          });
      } else {
        db.collection("accounts")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.accounts.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            if (
              this.$store.state.todaysDate &&
              this.$store.state.todaysDate.length != 0
            ) {
              let filtered = this.accounts.filter(
                (item) => item.dateTime == this.$store.state.todaysDate
              );
              this.filteredAccounts = filtered;
            } else {
              this.filteredAccounts = this.accounts;
            }
            //keep accounts in vuex store
            this.$store.commit("SET_ACCOUNTS", this.accounts);
            this.loading = false;
          });
      }
    },
    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },

    onSelectShop() {
      this.loading = true;
      this.accounts = [];
      db.collection("accounts")
        .where("businessId", "==", this.selectedShop)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.accounts.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          if (
            this.$store.state.todaysDate &&
            this.$store.state.todaysDate.length != 0
          ) {
            let filtered = this.accounts.filter(
              (item) => item.dateTime == this.$store.state.todaysDate
            );
            this.filteredAccounts = filtered;
          } else {
            this.filteredAccounts = this.accounts;
          }
          //keep accounts in vuex store
          this.$store.commit("SET_ACCOUNTS", this.accounts);
          this.loading = false;
        });
    },

    deleteDialog(accountId, accountName) {
      this.accountId = accountId;
      this.accountName = accountName;
      this.delDialog = true;
    },
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },
    dateFormat(dateTime) {
      var date = dateTime.toDate();
      return format(date, "EEE, dd MMMM, yyyy");
    },
    deleteAccount() {
      this.loading = true;
      db.collection("accounts")
        .doc(this.accountId)
        .delete()
        .then(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackSuccess = true;
          this.getAccounts();
        })
        .catch(() => {
          this.loading = false;
          this.delDialog = false;
          this.snackError = true;
        });
    },

    dateFilter() {
      let filtered = this.accounts.filter(
        (item) => item.dateTime == this.accountingDate
      );
      this.filteredAccounts = filtered;
    },
    sortBy(prop) {
      var new_array;
      if (prop == "all") {
        this.menu[0].selected = true;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.menu[5].selected = false;
        //get all orders from vuex store
        this.accounts = this.$store.state.accounts;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.accounts.filter(
            (item) => item.dateTime == this.$store.state.todaysDate
          );
          this.filteredAccounts = filtered;
        } else {
          this.filteredAccounts = this.accounts;
        }
      } else if (prop == "assets") {
        this.menu[0].selected = false;
        this.menu[1].selected = true;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.menu[5].selected = false;
        this.accounts = this.$store.state.accounts;

        new_array = this.accounts.filter(
          (element) => element.accountType == "assets"
        );

        this.accounts = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.accounts.filter(
            (item) => item.dateTime == this.$store.state.todaysDate
          );
          this.filteredAccounts = filtered;
        } else {
          this.filteredAccounts = this.accounts;
        }
      } else if (prop == "liabilities") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = true;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.menu[5].selected = false;
        this.accounts = this.$store.state.accounts;

        new_array = this.accounts.filter(
          (element) => element.accountType == "liabilities"
        );

        this.accounts = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.accounts.filter(
            (item) => item.dateTime == this.$store.state.todaysDate
          );
          this.filteredAccounts = filtered;
        } else {
          this.filteredAccounts = this.accounts;
        }
      } else if (prop == "expenses") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = true;
        this.menu[4].selected = false;
        this.menu[5].selected = false;
        this.accounts = this.$store.state.accounts;

        new_array = this.accounts.filter(
          (element) => element.accountType == "expenses"
        );

        this.accounts = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.accounts.filter(
            (item) => item.dateTime == this.$store.state.todaysDate
          );
          this.filteredAccounts = filtered;
        } else {
          this.filteredAccounts = this.accounts;
        }
      } else if (prop == "revenues") {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = true;
        this.menu[5].selected = false;
        this.accounts = this.$store.state.accounts;

        new_array = this.accounts.filter(
          (element) => element.accountType == "revenues"
        );

        this.accounts = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.accounts.filter(
            (item) => item.dateTime == this.$store.state.todaysDate
          );
          this.filteredAccounts = filtered;
        } else {
          this.filteredAccounts = this.accounts;
        }
      } else {
        this.menu[0].selected = false;
        this.menu[1].selected = false;
        this.menu[2].selected = false;
        this.menu[3].selected = false;
        this.menu[4].selected = false;
        this.menu[5].selected = true;
        this.accounts = this.$store.state.accounts;

        new_array = this.accounts.filter(
          (element) => element.accountType == "equity"
        );

        this.accounts = new_array;

        if (
          this.$store.state.todaysDate &&
          this.$store.state.todaysDate.length != 0
        ) {
          let filtered = this.accounts.filter(
            (item) => item.dateTime == this.$store.state.todaysDate
          );
          this.filteredAccounts = filtered;
        } else {
          this.filteredAccounts = this.accounts;
        }
      }
    },
    viewAccount(params) {
      this.$router.push({ name: "ViewAccount", params: { id: params } });
    },
    editAccount(params) {
      this.$router.push({ name: "UpdateAccount", params: { id: params } });
    },
    newAccount() {
      this.$router.push({
        name: "NewAccount",
      });
    },
    goBack() {
      this.$router.push({
        name: "DashboardAccount",
      });
    },
  },
};
</script>
